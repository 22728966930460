.formioSolicitud .form-control{
    width: 100%;
}

/**** Estilo selectores ****/
.selects {
    width: 350px;
}

/* Asteriscos que indican que el campo es obligatorio*/
td[ref="datagrid-tablaConfiguracionGlobalDeAnexos"] > div:nth-child(1) > label:nth-child(1),
td[ref="datagrid-tablaConfiguracionDeFormularios"] > div:nth-child(1) > label:nth-child(1),
td[ref="datagrid-tablaConfiguracionDeAnexos"] > div:nth-child(1) > label:nth-child(1) {
    left: 0px;
    right: 0px;
    text-align: end;
}

/* Campos de tamaño*/
.tamano {
    width: 70px;
}

.textos {
    width: 100px;
}

/**** Añadir/Eliminar Anexos****/
td[ref="datagrid-tablaConfiguracionDeAnexos"] > div:nth-child(1) {
    margin-left: 7px;
    margin-right: 7px;
}

/* Selectores */
td[ref="datagrid-tablaConfiguracionDeAnexos"] > div[class*="selects"] {
    width: 200px !important;
}

td[ref="datagrid-tablaConfiguracionDeAnexos"] > div:nth-child(1) > div[data-type="select-one"] {
    width: 200px !important;
}
/* Condición */

td[ref="datagrid-tablaConfiguracionDeAnexos"] > div:nth-child(1) > div[ref="element"] > div.choices:nth-child(1),
td[ref="datagrid-tablaConfiguracionDeAnexos"] > div:nth-child(1) > div[ref="element"] > div.choices:nth-child(1) > .choices__inner {
    width: 180px;
}

/* Alineación Encabezados Añadir/Eliminar Anexos */
div[class*="formio-component-tablaConfiguracionDeAnexos"] > table > thead > tr > th {
    padding-left: 15px !important;
}

/* Elimina el scroll en el textArea */
#descripcionAnexos {
    scrollbar-width: none;
}

/* Botón añadir todas las acciones */
div[class*="formio-component-btnAnadirTodasAcciones"] {
    text-align: end;
}
/* Se reduce el ancho de los td de la tabla acciones */
td[ref="datagrid-tablaAcciones"] {
    width: 440px;
}

.choices[data-type*=select-one]:after {
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-color: var( --color-eg-cien) transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 43%;
    transform: translateY(-50%);
    pointer-events: none;
}

