/**************************************************************************************
En este fichero se sobreescribiran las clases de los componentes genéricos de Bootstrap
**************************************************************************************/
@import './AppStyle.css';

/**** PAGINA ****/
.wizard-page{
    padding: 15px 40px 31px 20px;
    width: 100%;
    flex-wrap: wrap;
}

/*Contenedor del formulario*/
.formio-form {
    width: 100%;
    height: auto;
}

.formio-form > div:nth-child(1) {
    width: 100%;
}

.formio-component {
    margin: 9px 12px;
}

/**** NAVEGACION PAGINAS  ****/

/*UL Paginacion*/
.pagination{
    margin-bottom: 0px;
    border-bottom: 1px solid var(--color-eg-cien) !important;
    justify-content: space-around;
}

/*LI Paginacion*/
.page-link{
    border: none;
    font-family: var(--tipografia-ssp-regular);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--color-eg-cien) !important;
    /*Se evitan los bordes*/
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.page-link:hover {
    background-color: transparent !important;
}

/*LI Paginacion Active*/
.page-item.active .page-link {
    border-bottom: 3px solid var(--color-pr-principal) !important;    
    font-family: var(--tipografia-ssp-regular);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--color-pr-principal) !important;
    background: transparent !important;
}

/**** PASO A PASO (Pagination) ****/
.pagination {
    list-style: none;
    display: inline-flex;
    width: 100%;
}
/*Pagina*/
.page-item {
    display: inline-flex;
    cursor: pointer;
    margin-top: 16px;
}
/*Pagina seleccionada*/
.page-link {
    padding-bottom: 16px;
    max-width: 300px;
    overflow: hidden;
    max-height: 50px;
    display: flex;
}

/**** Labels del formulario ****/
.col-form-label {
    color: var(--color-eg-cien) !important;
    font-family: var(--tipografia-ssp-regular) !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px !important;
    letter-spacing: 0px;
    text-align: left;
    margin: 0px 0px 6px 0px !important;
    padding: 0px !important;
}
/**** Inputs ****/
.form-control, .choices__inner {
    color: var(--color-eg-cien) !important;    
    background-color: transparent !important;
    min-height: 40px;
    width: 350px;
    border: 1px solid var(--color-pr-principal) !important;
    box-shadow: 0 0 0 1px transparent !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    font-family: var(--tipografia-ssp-regular);
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0px;
    text-align: left;
    padding: 8px 0px 8px 15px !important;
    margin: 0px 24px 18px 0px;
}

/* Se elimina el fondo de los campos con autocompletado */
.choices__inner {
    background-color: transparent;
    height: auto !important;
}

.form-control:hover, .form-control:focus,
.choices__inner:hover, .choices__inner:focus {
    border-color: transparent !important;
    /*Se utiliza box-shadow en lugar de border para evitar el desplazamiento interno del texto*/
    box-shadow: 0 0 0 2px var(--color-pr-principal) !important;
}

.form-control:disabled,
.choices__inner:disabled {
    background-color: var(--color-eg-cinco) !important;
    border: 1px solid var(--color-eg-apoyo) !important;
}
.form-control:disabled:hover,
.choices__inner:disabled:hover {
    box-shadow: 0 0 0 2px var(--color-eg-apoyo) !important;
}

/*Grupos añadidos a la derecha d elos inputs (recuadro con icono)*/
.input-group-append {
    /*Se oculta y se quita el tamaño para evitar que ocupe espacio*/
    visibility: hidden;
    height: 0px;
    width: 0px;
}

/**** Deshabilitado de estilos propios ****/
:focus {
    outline: none;
}
/**** RadioButtons y CheckBoxs ****/
.form-check-input {
    border: 1px solid var(--color-pr-principal) !important;
    cursor: pointer !important;
}
/* RadioButtons y CheckBoxs seleccionados*/
.form-check-input:checked {
    background-color: var(--color-pr-principal);
}

/* Solo para navegadores firefox; la librería html2canvas falla al renderizar los svg de 
background-image. Sustituyendolo por image/png el paso a PDF se realiza correctamente **/
@-moz-document url-prefix() {
    .form-check-input:checked[type="radio"] {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAACCSURBVDhP3Y9LDoAgDETBnafzdyld6J2MW8+j7nCA0USF4lZfMmlJX4Cqn2KMKZARWRjbFxzLQGyRGB21MBDsyylK6k8wtF9NMVF3aFYHhgtK7k9RNq316WSsB5cL33C/YGaViDtYoXJbyjTUw0DovRdkoCYDsUYmZGVsL7/8VZTaATdx5OWVOHTfAAAAAElFTkSuQmCC') !important;
    }
    .form-check-input:checked[type="checkbox"] {
        background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACHSURBVDhP7ZLRCoAgFEPv7Terl34reuw7bdMZEZVG1FMHhtrdhoH28x2utZoQAjNtOtns7kH7elDSQBOU6TWyRmsRhOgdoSF+uAJmhzpp/W2WQNubEJ6PL4JBHy2JaJTqSwiG2yLCwL0SQoOMZ5RLMjQqsKe+JMOAgpliyemDRPD5w/t5A7MFOyPxRmfwHmoAAAAASUVORK5CYII=') !important;
        }
}

.form-check-input:hover, .form-check-input:focus,
.form-check-input:checked:hover, .form-check-input:checked:focus {
    box-shadow: none !important;
}


/**** SELECTS ****/
/*Opciones del menu*/
.selection {
    height: 40px;
}
.choices__list--dropdown {
    background: var(--color-eg-cero) !important;
    font-family: var(--tipografia-ssp-regular) Pro !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--color-eg-ochenta) !important;
}
/*Opción seleccionada del menú*/
.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: var(--color-pr-fondoEspecifico) !important;
}
/*Campo autocompletado*/
.choices__input {
    padding: 0;
    background-color: transparent !important;
    height: auto !important;
}
.choices__input.choices__input--cloned {
    border: none !important;
}

/**** Buttons ****/
/*Button basic*/
.btn {
    /*Text*/
    font-family: var(--tipografia-ssp-regular);
    font-style: normal;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    /*Button*/
    border-radius: 4px !important;
    height: 40px;
    width: auto;
    min-width: 135px;
    cursor: pointer !important;
    border: 1px solid var(--color-pr-principal) !important;
}
.btn:hover, .btn:focus {
    background-color: var(--color-pr-hover) !important;
}
.btn:disabled {
    background-color: var(--color-pr-apoyo) !important;
}

/*Button primary*/
.btn-primary {
    color: var(--color-eg-cero) !important;
    background: var(--color-pr-principal) !important;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: var(--color-pr-hover) !important;
    box-shadow: 0 0 0 0 transparent;
}

/*Button secondary*/
.btn-secondary {
    color: var(--color-pr-principal) !important;
    background: transparent !important;
}

.btn-secondary:hover, .btn-secondary:focus {
    color: var(--color-pr-hover) !important;
    border-color: var(--color-pr-hover) !important;
    background-color: transparent !important;
}

.btn-secondary:disabled {
    color: var(--color-pr-apoyo) !important;
    border-color: var(--color-pr-apoyo) !important;
    background-color: transparent !important;
}

/*Botones en lista*/
.list-inline {
    text-align: end;
}
.list-inline-item {
    margin: 0px 0px 0px 24px !important;
}

/**** Tabla ****/
.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--color-eg-cien) !important;
    --bs-table-striped-bg: var(--color-pr-apoyo1) !important;
    --bs-table-active-color: var(--color-eg-cien) !important;
    --bs-table-active-bg: var(--color-pr-apoyo1) !important;
    --bs-table-hover-color: var(--color-eg-cien) !important;
    --bs-table-hover-bg:var(--color-pr-apoyo1) !important;
    width: 100%;
    margin-bottom: 0 !important;
    color: var(--color-eg-cien) !important;
    vertical-align: top;
    border-color: transparent !important;
    /*Texto*/
    font-family: var(--tipografia-ssp-regular) !important;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
}

.table-responsive {
    width: 100%;
}

/**** Acordeón ****/
/*Div contraído*/
.card-header.bg-default {
    height: 56px;
    padding-top: 15px;
    padding-left: 20px;
    background-color: var(--color-eg-cero);
    border-top: 1px solid var(--color-eg-apoyo);
    border-bottom: 0px !important;
    border-radius: 0 !important;
}
/*Texto contrído*/
.card-title {
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: var(--color-pr-principal);
}
.card.border {
    border: 0px !important;
    margin: 0px !important;
    background: var(--color-pr-fondoEspecifico);
}
/*****
En estilo que se aplique sobre el componente acordeón, se deberá insertar un id al último acordeón y sustituir el nombre del id
que aparece en este ejemplo e insertar esta última clase en el estilo del propio componente.

.formio-component-idAsignado > div:nth-child(1) > div:nth-child(1) {
    border-bottom: 1px solid var(--color-eg-apoyo) !important;
}
****/

/**** Validaciones ****/
.formio-error-wrapper {
    padding: 0px !important;
    background-color: transparent !important ;
}

.form-control.is-invalid {
    box-shadow: 0 0 0 2px transparent !important;
    border: solid 1px var(--color-es-error) !important;
}

.form-control.is-invalid:hover {
    box-shadow: 0 0 0 2px var(--color-es-error) !important;
    border: solid 1px transparent !important;
}

.alert-danger {
    border-radius: 0px !important;
    border-color: var(--color-es-error) !important;
    background-color: var(--color-es-alert) !important;    
    margin-bottom: 0px !important;
}
.alert {
    margin-right: 2%;
}

div[id^="error-list"] > ul:nth-child(2) {
    color: var(--color-es-textAlert);
    text-decoration: underline;
    list-style: none;
    cursor: pointer;
}

div[id^="error-list"] > p:nth-child(1) {
    color: black;
    font-weight: bold;
    list-style: none;
}

.ul {
    text-decoration: underline !important;
    color: #B00020 !important;
}

.error {
    color: var(--color-es-error) !important;
}

/**** TEXTOS ****/
/*Titulos*/
h1 {
    font-family: var(--tipografia-ssp-regular);
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 32px !important;
    width: max-content;
    height: max-content;
}
h2 {
    font-family: var(--tipografia-ssp-regular);
    font-size: 26px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 32px !important;
    width: max-content;
    height: max-content;
}
h3 {
    font-family: var(--tipografia-ssp-regular);
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 32px !important;
    width: max-content;
    height: max-content;
}
h4 {
    font-family: var(--tipografia-ssp-regular);
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 26px !important;
    height: max-content;
}
h5 {
    font-family: var(--tipografia-ssp-regular);
    font-size: 17px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px !important;
    height: max-content;
}

p, span {
    font-family: var(--tipografia-ssp-regular);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

td {
    border-width: 0px !important;
}

/***** Estilo para los mensajes de error al pie del componente *****/
.form-text {
    margin-bottom: 5%;
}

.help-block {
    color: var(--color-pr-required);
}

/***** Sobreescritura de componentes de FORMIO *****/

/***** Módulo de administración *****/

/*Alinea horizontalmente los inputs de la configuración de @orve del módulo de administración */
div[ref="nested-seccionConfiguracionOrve"] {
    display: inline-flex !important;
}

/* Color de los * de obligatorio*/
.field-required:after, .tab-error::after {
    color: var(--color-pr-principal) !important;
}

/* Modificar el botón Atrás */
#btnAtras {
    background-color: #fff !important;
    border: 2px solid #cc0000 !important;
    color: #cc0000 !important;
    padding-left: 10px; 
    position: relative;
}

#btnAtras::before {
    content: '<'; 
    position: absolute;
    left: 10px;
    font-size: 20px; 
    color: #cc0000;
}

#btnAtras:hover {
    background-color: #b00202 !important;
    border-color: #b00202 !important;
    color: #fff !important;
}

#btnAtras:hover::before {
    color: #fff;
}