@import './../../../AppStyle.css';

.btn.btn-xxs.btn-secondary.component-settings-button{
    padding: 2px;
    height: fit-content;
    width: fit-content;
}
.btn.btn-xxs.btn-default.component-settings-button {
    padding: 2px;
    height: fit-content;
    width: fit-content;
}
.btn.btn-xxs.btn-danger.component-settings-button{
    padding: 2px;
    height: fit-content;
    width: fit-content;
}

.save-back-btn {
    width: max-content !important;
    padding: 0px !important;
    margin: auto 0px 0px auto;
}

#tituloPaginaFormBuilder {
    color: var(--color-eg-cien);
    font-size: 26px;
    font-style: normal;
    margin-top: 2%;
    font-family: var(--tipografia-ssp-regular);
    font-weight: 700;
    line-height: 32px;
}

/**** Contenedor principal ****/
#containerCreacionForm {
    width: 100%;
}
/**** Cabecera del menú de creación de formularios ****/
#cabeceraCreacionForm {
    margin: 0 0 1.5rem 0;
    align-items: end;
}
/* Se evita el desplazamiento a la izquierda del campo título*/
div.col-lg-2:nth-child(1) {
    padding-left: 0px;
}

/**** Cuadro donde se arrastran los componentes ***/
.formioBuilderScope .alert-info {
    width: 100%;
}

#builder-sidebar-ek0mx6e {
    border: 1px solid #ced4da;
    border-radius: 4px;
}

/**** Formcomponentes (menu izquierda) ****/
.col-xs-4.col-sm-3.col-md-2.formcomponents {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0px;
}

.formio.builder.row.formbuilder {
    margin-left: 0px;
}

/* Buscador de componentes */
.form-control.builder-sidebar_search {
    width: 95%;
    margin: 2.5%;
}
/*Apartado de componentes*/
.formioBuilderScope .card {
    border: 0px;
}

/* Submenu recursos */
#group-container-resource {
    border-radius: 4px;
    width: 100%;    
}

/**** Formarea (contenedor derecho)****/
.formarea {
    padding-left: 2% !important;
}

/* Botón enviar */
.btn-md {
    margin: 12px 0px !important;
}