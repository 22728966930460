/***********************************************************************************************************
En este fichero se sobreescribiran las clases de que se aplicaran al modulo de administracion de formularios
***********************************************************************************************************/
@import './AppStyle.css';

/**** MIGAS DE PAN ****/
/*Página actual*/
.mr-2.badge.badge-primary.wizard-page-label {    
    color: var(--color-es-cien);
}
/*Siguientes páginas*/
.mr-2.badge.badge-info.wizard-page-label {
    color: var(--color-pr-principal);
}
.mr-2.badge.badge-info.wizard-page-label:hover {
    text-decoration: underline;
}
.mr-2.badge.badge-info.wizard-page-label:focus {
    text-decoration: underline;
}
/*Botón añadir página*/
.mr-2.badge.badge-success.wizard-page-label {
    background-color: var(--color-pr-principal);
}
.mr-2.badge.badge-success.wizard-page-label:hover {
    background-color: var(--color-pr-hover);
}
.mr-2.badge.badge-success.wizard-page-label:focus {
    background-color: var(--color-pr-hover);
}
/*Separación de las migas de pan del margen superior*/
ol.breadcrumb {
    margin-top: 16px;
}

/**** Desplegable ****/
.MuiTablePagination-input {
    border: 2px solid var(--color-pr-principal);
    border-radius: 4px;
}
/*Desplegable selector de numero de paginas Seleccionado*/
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiTablePagination-menuItem.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
    background-color: var(--color-pr-apoyo);
}

/*Se evita que se inserte un margin-right en las opciones del Selector de numero de paginas */
.MuiList-root.MuiMenu-list.MuiList-padding {
    margin-right: 0px !important;
}

/*Listas del desplegable*/
.MuiSelect-selectMenu{
    font-family: var(--tipografia-ssp-regular) !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    text-transform: uppercase;
    color: var(--color-eg-cien) !important;
}
.MuiSelect-selectMenu:hover, .MuiSelect-selectMenu:focus  {
    background: transparent;
}

/* Opciones del desplegable */
.MuiListItem-root {
    background: var(--color-eg-cero);
    color: var(--color-eg-cien);
}

.MuiPopover-paper {
    background-color: var(--color-eg-cero) !important;
}
/*Se evita el exceso de margin*/
.MuiMenu-list, .MuiListItem-gutters {
    margin-right: 0px;
}
/*Opcion seleccionada*/
.MuiListItem-root.Mui-selected {
    background-color: var(--color-pr-apoyo);
}

.MuiListItem-button:hover, .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background: var(--color-pr-apoyo1) !important;
}

/****Botones para cambiar de pagina****/
.MuiIconButton-root {
    color: var(--color-eg-cero) !important;
    background: var(--color-pr-principal) !important;
    margin-left: 5px !important;
    border-radius: 4px !important;
}
.MuiIconButton-root:disabled {
    background: var(--color-pr-apoyo) !important;
}

/**** Tipografia ****/
/*Tipografia basic*/
.MuiTypography-root {
    margin: 0px !important;
    font-family: var(--tipografia-ssp-regular) !important;
}
/*Body 2*/
.MuiTypography-body2 {
    font-size: 13px;
    line-height: 16px;
}

h5 {
    font-family: var(--tipografia-ssp-regular) !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
}

/**** Botones ****/
.btn {
    margin-left: 10px;
    font-family: var(--tipografia-ssp-regular);
    font-size: 13px;
}

.form-control:read-only {
    background-color: transparent !important;
}

/*Alinea a la izquierda el texto del menú de componentes*/
button[ref="sidebar-anchor"] {
    text-align: left !important;
}