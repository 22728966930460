/*Clase especifica*/

/*Margen formulario*/
.formio-form {
    margin-bottom: 5%;
  }
  
  /*Pagina Datos de contacto*/
  #h3datosPersonales,
  #h4interesado {
    margin-top: 28px;
  }
  /*Containers*/
  /*Se capturan de esta forma por que formio inserta un div dentro del container que contiene los elementos y este div no se puede referenciar.*/
  .formio-component-containerPersona > div:nth-child(1), .formio-component-containerEntidad > div:nth-child(1), .formio-component-containerDomicilio > div:nth-child(1), /*Pagina datos de contacto*/
  .formio-component-containerFecha > div:nth-child(1), .formio-component-containerFirma > div:nth-child(1) /*Pagina solicitud y declaraion*/ {
    display: inline-flex;
    flex-wrap: wrap; /*Evita desbordamiento*/
  }
  
  /**** Pagina solicitud y declaracion ****/
  /*Parrafos de la pagina solicitud y declaracion*/
  #parfEn,
  #parfA,
  #parfDe,
  #parfSolicitanteRepresentante {
    margin-top: 35px;
    margin-bottom: 5px;
  }
  #parfSolicitanteRepresentante {
    margin-right: 48px;
  }
  
  /*Inputs y selects*/
  #lugar {
    width: 210px !important;
  }
  #dia {
    width: 73px !important;
  }
  #mes,
  #anho {
    width: 147px !important;
  }
  
  /*Clase que desplaza hacia abajo a los elementos de los containers para los titulos queden en el encabezado*/
  .titulos {
    margin-right: 80% !important;
    margin-bottom: 6px !important;
  }
  
  #datosAdjuntos {
    margin-bottom: 22px;
  }
  #autorizacion2 {
    margin-top: 33px;
  }
  
  
  /****TABLA DINAMICA****/
  
  /*Celdas (Inputs)*/
  #identificacionFiscal,
  #identificacion,
  #importe,
  #importeCalculado {
    font-weight: 400 !important;
    background-color: var(--color-pr-apoyo1) !important;
    color: var(--color-gr-cien) !important;
  }
  /*Primera celda*/
  #identificacionFiscal {
    color: var(--color-pr-principal) !important;
    font-weight: 600 !important;
  }
  
  /*Data Grid*/
  .table > tbody:nth-child(2) {
    background: none;
  }
  
  .table > thead:nth-child(1) {
    border-bottom: 1px solid var(--color-eg-cien);
  }
  
  .table > thead:nth-child(1) > tr:nth-child(1) > th {
    padding-left: 20px;
  }
  
  /*Boton eliminar fila*/
  .btn.btn-secondary.formio-button-remove-row {
    margin: 9px 0px;
    border: none !important;
    min-width: 40px;
  }
  
  /*Posicion del * en los campos required de la tabla*/
  .control-label--hidden {
    position: absolute !important;
    top: 0px;
    right: 14px;
    font-size: 1.5em;
  }

  
  /*Icono para borrar filas de celdas en las tablas*/
  .fa.fa-times-circle-o {
    color: var(--color-es-error);
  }
  
  /*Container importes totales*/
  .containerImportesTotales {
    padding-left: 608px;
  }
  .containerImportesTotales > div:nth-child(1) {
    display: inline-flex;
    flex-wrap: wrap;
  }
  
  /*Inputes container importes totales*/
  .importesTotales,
  #importeTotal,
  #totalImporteCalculado {
    width: 250px !important;
    margin-right: 41px !important;
  }
  
  /*Margen de botones*/
  .formioSolicitud .formio-form > div:nth-child(1) > ul {
    padding: 3%;
  }
  .containerHorizontal > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  
  /*Botones de guardado de paso
  ** Para que se aplique este estilo a los botones de guardado del formulario, hay que insertar el id y el name con valor btnGuardar
  */
  div[class*="formio-component-btnGuardar"] {
    text-align: end;
  }
  
  .formioSolicitud .alert {
    margin-right: 0px;
  }
  
  .formio-component-containerBtnCancelarGuardar {
    text-align: end;
  }
  div[ref="nested-containerBtnCancelarGuardar"],
  div[ref="nested-informacionBoja"],
  div[ref="nested-centralizada"],
  div[ref="nested-provincializada"] {
    display: inline-flex;
  }
  
  #btnCancelar {
    margin-top: 12px !important;
  }
  .divBtnCancelar {
    position: absolute;
    right: 100px;
  }

  .setTop6px {
    margin-top: 6px !important;
  }
  
  /*Tamaño label componentes*/
  

 /*Mensaje de error en el formulario*/
  div.form-text.error {
    margin-top: -4%;
  }
  div.mensajeErrorRadio .form-text.error:last-child{
    margin-top: 0%;
    margin-bottom: 0%;
  }
  div.mensajeErrorTextArea .form-text.error:last-child{
    margin-top: -1%;
    margin-bottom: 0%;
  }
  .campoCodZonaGris .form-text.error {
    margin-top: -2%;
  }
  /*Modificaciones de los campos grid de las tablas*/
  .formioSolicitud .campoGridCorto .form-control.is-invalid,.formioSolicitud .campoGridLargo .form-control.is-invalid {
    margin-bottom: 0%;
  }

  div.campoGridCorto .form-text.error:last-child, div.campoGridLargo .form-text.error:last-child{
    margin-top: 0%;
  }
  .campoGridCorto{
    width: 80%;
  }
  .campoGridLargo{
    width: 100%;
  }
  button.btn.btn-success.formio-dialog-button{
    display: none;
  }

  .campoGridCorto .choices.form-group.formio-choices {
    width: 100%;
  }
  .campoCodZonaGris .choices__inner{
    width: 100%;
  }
  .campoCodZonaGris input.choices__input.choices__input--cloned{
    display: none;
  }
  .campoCodZonaGris .choices.form-group.formio-choices{
    width: 100%;
  }
  .campoCodZonaGris .choices.form-group.formio-choices.is-disabled{
    width: 100%;
  }


  /*Modificación del formio-hidden en las clases campoExtendido y campoNormal*/
  .campoExtendido.formio-hidden {
    display: none;
  }
  .campoNormal.formio-hidden {
    display: none;
  }
  /*Estilo de los campos para su división en columnas*/
  .campoCodZonaGris{
    display: inline-grid;
    height: 100px;
    width: 55.6%;
    left: 0px;
    top: 22px;
    border-radius: 4px;
    align-content: baseline;
    
  }
  .campoNormal{
    display: inline-grid;
    height: 100px;
    width: 163px;
    left: 0px;
    top: 22px;
    border-radius: 4px;
    align-content: baseline;
  }
  .campoExtendido{
    display: inline-grid;
    height: 100px;
    width: 350px;
    left: 0px;
    top: 22px;
    border-radius: 4px;
    align-content: baseline;
  }
  .campoExtendidoNoLine{
    height: 100px;
    width: 350px;
    left: 0px;
    top: 22px;
    border-radius: 4px;
    align-content: baseline;
  }
  /* Igual formato que campoNormal y campoExtendido pero sin forzar el ancho */
  .campoEstandar{
    display: inline-grid;
    height: 100px;
    left: 0px;
    top: 22px;
    border-radius: 4px;
    align-content: baseline;
  }
  /* Igual formato que campoEstandar pero sin limitar el alto */
  .campoTextoGrande{
    left: 0px;
    top: 22px;
    margin-bottom: 50px !important;
    border-radius: 4px;
    align-content: baseline;
  }
  .campoTabulado{
    margin-left: 40px !important;
  }
  .largoNormal{
    width: 163px;
  }
  .deshabilitado.textarea{
    background-color: var(--color-eg-cinco);

  }
  /*.formioSolicitud .formio-component{
      margin: 2.5em 1.5em;
  }*/
  
  .formioSolicitud .col-form-label {
    /*height: 16px;
    width: 55px;
    left: 0px;
    top: 0px; */
  
    /*esto todavía no está probado*/
  
    border-radius: nullpx;
    font-family: var(--tipografia-ssp-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  /*Tamaño label pestañas*/
  
  .formioSolicitud .page-item {
    /* Procedimientos */
  
    /*position: absolute; se descoloca la pestaña*/
    height: 40px;
    left: 9.52%;
    right: 78.33%;
    top: calc(50% - 32px / 2 - 461.5px);
  
    /* Headlines/Headline 3 */
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    /* identical to box height, or 145% */
  
    /* Negro */
  
    color: var(--color-eg-cien);
  
  }


  /* Propiedades específicas para los elementos form-check-input con la clase '.radioSquare' */
  .radioSquare .form-check-input[type="radio"] {
    border-radius: 0.2em !important;
    margin-right: 0.5em !important;

    
  }

  .radioSquare .form-check-input:checked[type="radio"] {
    background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACHSURBVDhP7ZLRCoAgFEPv7Terl34reuw7bdMZEZVG1FMHhtrdhoH28x2utZoQAjNtOtns7kH7elDSQBOU6TWyRmsRhOgdoSF+uAJmhzpp/W2WQNubEJ6PL4JBHy2JaJTqSwiG2yLCwL0SQoOMZ5RLMjQqsKe+JMOAgpliyemDRPD5w/t5A7MFOyPxRmfwHmoAAAAASUVORK5CYII=') !important;
  }

  /* Custon Class genéricos para forzar margenes personalizados en los componentes */
    .margenInferior{
    margin-bottom: 2% !important;
  }
   .margenSuperior{
    margin-top: 2% !important;
  }
