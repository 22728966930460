/***********************************************************************************
En este fichero se declararán las variables de BootStrap que se deseen sobreescribir
***********************************************************************************/

/**** Colores ****/
$primary: #cc0000;
$secondary: #ffa7a7;
$success:#2ABC67;
$info: #5BC0DE;
$warning: #F0AD4E;
$danger: #d4403a;

/**** RadioButtons y CheckBox ****/
$form-check-input-border: 1px solid $primary;
$form-check-input-checked-color: white;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: 1px solid $primary;