/*Declaración de variables a utilizar en archivos css*/
:root {
    /**** COLORES ****/
    /*Principales*/
    --color-pr-hover: #b00202;                        
    --color-pr-principal: #cc0000;
    --color-pr-apoyo: #ffa7a7;
    --color-pr-apoyo1: #ffa7a7; /*NO CAMBIADO / PROVISIONAL*/
    --color-pr-fondoEspecifico: #ffe4e4;
    --color-pr-blanco: #FFFFFF; /*NO CAMBIADO*/ 
    --color-pr-required: #5f5e5e; /*NO CAMBIADO*/
    --color-pr-verde: #087021;
    --color-pr-azul: #276afb;
    --color-pr-morado: #9831AD;
    /*Escala de grises*/
    --color-eg-cien: #333333;
    --color-eg-noventa: #444444;
    --color-eg-ochenta: #555555;
    --color-eg-setenta: #7B7B7B;
    --color-eg-sesenta: #909090;
    --color-eg-cincuenta: #999999;
    --color-eg-cuarenta: #B3B3B3;
    --color-eg-treinta: #C8C8C8;
    --color-eg-veinte: #D8D8D8;
    --color-eg-diez: #EEEEEE;
    --color-eg-cinco: #F5F5F5;
    --color-eg-cero: #FFFFFF;
    --color-eg-principal: #333333;
    --color-eg-contenedor: #F5F5F5;
    --color-eg-secundario: #767676;
    --color-eg-apoyoEspecífico: #EEEEEE;
    --color-eg-apoyo: #BEBEBE;
    --color-eg-oscuro: #242424;
    /*Estado*/
    --color-es-exito: #2ABC67;
    --color-es-informacion: #5BC0DE;
    --color-es-atencion: #F0AD4E;
    --color-es-error: #d4403a;
    --color-es-alert: #FFF5F7; /*NO CAMBIADO*/ 
    --color-es-textAlert: #B00020;  /*NO CAMBIADO*/
    /**** Tipografias ****/
    /*Source Sans 3*/
    --tipografia-ssp-regular: Source Sans 3;
    --tipografia-ssp-bold: Source Sans 3 Bold;
    --tipografia-ssp-light: Source Sans 3 Light;
    --tipografia-ssp-medium: Source Sans 3 Medium;
    --tipografia-ssp-thin: Source Sans 3 Thin;

    /*Noto Sans HK*/
    --tipografia-nshk-regular: Noto Sans HK Regular;
    --tipografia-nshk-bold: Noto Sans HK Bold;
    --tipografia-nshk-light: Noto Sans HK Light;
    --tipografia-nshk-medium: Noto Sans HK Medium;
    --tipografia-nshk-thin: Noto Sans HK Thin;
}

/* No subir a desarrollo */
/* https://stackoverflow.com/questions/69051008/react-injecting-iframe-with-max-z-index-on-reload-after-changes-development */
body > iframe[style*='2147483647']{
    display: none;
  }