#columnAccionesTabla1, #columnAccionesTabla2, #columnAccionesTabla3 {
    width: 10%;
}

/* #idFirmaAdjunto, #nombreAdjunto, #fechaGuardadoAdjunto {
    width: 45%;
}

#idSolicitud, #numExpediente, #nombreFichero, #fechaPresentacion, #fechaGuardado, #estado {
    width: 30%;
} */