@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  src: url('assets/SourceSans3-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans 3 Bold';
  font-style: bold;
  font-weight: 700;
  src: url('assets/SourceSans3-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans 3 Light';
  font-style: normal;
  src: url('assets/SourceSans3-Light.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans 3 Medium';
  font-style: normal;
  src: url('assets/SourceSans3-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans 3 Thin';
  font-style: normal;
  src: url('assets/SourceSans3-ExtraLight.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans 3 Medium';
  font-style: normal;
  src: url('assets/SourceSans3-SemiBold.ttf') format('truetype')
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:'Source Sans 3';
  font-style: normal;
}

* :focus  {
  outline: 2px solid var(--color-pr-principal);
}

/*Se añade el estilo a las etiquetas <a> debido al texto que pueda venir en formato HTML desde un endPoint para los componentes mensajeAydua y altaUsuario*/
a{
  color: var(--color-pr-principal);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  text-decoration: underline;
}
