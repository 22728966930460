#textArea {
    height: 250px;
    width: 100% !important;
    text-justify: distribute;
}

.formio-component-terceraSeccion > div:nth-child(1) > div:nth-child(1) {
    border-bottom: 1px solid var(--color-eg-apoyo) !important;
}

/***** Btn guardar (Modulo adminsitración)*****/
#btnGuardar {
    margin-top: 5%;
    margin-bottom: 2%;
}

.divBtnGuardar {
    text-align: end;
}

